body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  padding-block-start: 0px !important;
  padding-block-end: 0px !important;
  padding-inline-start: 0px !important;
  padding-inline-end: 0px !important;
}

p {
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  padding-block-start: 0px !important;
  padding-block-end: 0px !important;
  padding-inline-start: 0px !important;
  padding-inline-end: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Padding left and right ul */
ul {
  padding-inline-start: 30px !important;
}

div,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
ul,
ol,
img,
input,
button,
form,
label,
textarea,
select,
option,
nav,
section,
header,
footer,
main,
article,
aside,
figure,
figcaption,
table,
td,
tr,
th,
tbody,
thead,
tfoot,
video,
audio,
iframe,
hr,
pre,
blockquote,
address,
time,
fieldset,
legend,
caption,
details,
summary,
small,
strong,
em,
b,
i,
u,
s,
strike,
del,
ins,
sup,
sub,
code,
kbd,
samp,
var,
mark,
q,
dfn,
abbr,
cite,
acronym,
button,
input,
textarea,
select,
option,
optgroup,
fieldset {
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

/* This will hide the scrollbar for the entire document */
body {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

body::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

li {
  list-style-type: circle;
}

li::marker {
  content: "• ";
  color: rgb(130, 130, 130);
  font-size: xx-large;
}
