.btn {
  color: white;
  background: rgb(156, 39, 176);
  border: solid rgb(156, 39, 176) 5px;
  border-radius: 90px;
  font-family: "Archivo Black", sans-serif;
  font-size: 135%;
  padding: 10px 20px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 300ms ease-in-out;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  -ms-border-radius: 90px;
  -o-border-radius: 90px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
}

.btn-hover-light:hover {
  background-color: transparent;
  color: white;
  border: solid white 5px;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
}

.btn-hover-dark:hover {
  background-color: transparent;
  color: rgb(30, 30, 45);
  border: solid rgb(30, 30, 45) 5px;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
}
